<template>
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block sbg-2"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-5 pb-lg-5 mt-3 rounded d-flex flex-column justify-content-center"
          style="background-color: rgba(255, 255, 255, 0.8)"
        >
          <img
            class="d-block"
            alt="頁面不存在示意圖"
            src="/images/error/404.svg"
            style="width: 300px; margin: 0 auto"
          />
          <h2 class="text-center mb-5">
            很抱歉，您所尋找的頁面不存在，請<a href="/" title="點此連結回到首頁"
              >回首頁</a
            >再試一次。
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Head from "@/components/layout/Head.vue";
// import Foot from "@/components/layout/Foot.vue";

export default {
  name: "Error404",
  data() {
    return {};
  },
  components: {
    // Head,
    // Foot,
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
</style>
